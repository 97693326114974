<template>
    <v-stepper v-model="stepNo">
        <v-stepper-header>
            <template v-for="(title, index) in titles">
                <v-stepper-step :complete="stepNo > index + 1" :step="index + 1" :key="'step'+index">{{title}}</v-stepper-step>
                <v-divider v-if="index !== titles.length - 1" :key="'devider'+index"></v-divider>
            </template>
        </v-stepper-header>
        <v-stepper-items>
            <v-stepper-content step="1" class="pa-0">
                <v-layout justify-center>
                    <v-flex xs12 sm8 md4>
                        <v-card flat>
                            <v-card-text>
                                <v-form ref="form" v-model="valid">
                                    <v-text-field outline clearable required
                                        prepend-icon="smartphone"
                                        name="phone"
                                        :label="$t('changePhone.phone-number')"
                                        type="tel"
                                        v-model="phone"
                                        :hint="$t('changePhone.phone-hint')"
                                        mask="##########"
                                        :rules="rules"
                                    ></v-text-field>
                                </v-form>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn large block class="subheading" color="primary" :disabled="btnDisabled" @click="submit">{{ btnName }}</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-stepper-content>
            <v-stepper-content step="2" class="pa-0">
                <confirm-auth
                    :user-id="userId"
                    :phone="phone"
                    @pass="passAuth"
                    @back="setStepNo(1)"
                ></confirm-auth>
            </v-stepper-content>
        </v-stepper-items>
        <waiting-dialog :show.sync="dialog.show" :msg="dialog.msg" :err-msg="dialog.err"></waiting-dialog>
        <message-dialog
            :show.sync="msgDialog.show"
            :msg="msgDialog.msg"
            :primary-btn="msgDialog.primaryBtn"
        ></message-dialog>
    </v-stepper>
</template>

<script>
import axios from 'axios'
import store from '@/store'
import ConfirmAuth from '@/components/ConfirmAuth'
import WaitingDialog from '@/components/WaitingDialog.vue'
import WaitDialogControl from '@/mixins/WaitDialogControl.vue'
import MessageDialog from '@/components/MessageDialog.vue'
import MessageDialogControl from '@/mixins/MessageDialogControl.vue'
import UserMixin from '@/mixins/UserMixin'
import { HostUrl, SideBtnType } from '@/store'
import { getAxiosConfig } from '@/utils/AuthService'
import { UserStatus } from '@/mixins/UserMixin'
import i18n from '@/i18n'

export default {
    name: 'ChangePhone',
    components: { ConfirmAuth, WaitingDialog, MessageDialog },
    mixins: [ WaitDialogControl, MessageDialogControl, UserMixin ],
    data() {
        return {
            titles: [`${i18n.t('changePhone.nav-change-number')}`, i18n.t('changePhone.verify-phone')],
            stepNo: 1,
            valid: false,
            userId: store.getters.user.id,
            phone: '',
            rules: [
                v => !!v || i18n.t('changePhone.rules.emptyErr'),
                v => /^09\d{8}$/.test(v) || i18n.t('changePhone.rules.validErr')
            ]
        }
    },
    computed: {
        btnName () {
            return this.checkUserStatus(UserStatus.PhoneVerified) ? i18n.t('changePhone.change') : i18n.t('changePhone.resend-SMS')
        },
        btnDisabled () {
            return !this.valid || (this.phone === store.getters.user.phone && this.checkUserStatus(UserStatus.PhoneVerified))
        }
    },
    methods: {
        init() {
            this.phone = store.getters.user.phone
            this.setStepNo(1)
        },
        setStepNo(no) {
            this.stepNo = no
            store.commit('setTitle', this.titles[this.stepNo - 1])
            this.closeDialog()
        },
        passAuth() {
            store.commit('setSnackBar', {
                message: i18n.t('changePhone.change-success'),
                color: 'success'
            })
            this.$router.back()
        },
        async submit() {
            if (this.$refs.form.validate()) {
                this.showDialog(i18n.t('changePhone.update-info'), i18n.t('changePhone.update-failed'))
                try {
                    let suburl = '/api/user/profile';
                    let response = await axios.patch(HostUrl + suburl, {
                        phone: this.phone
                    }, getAxiosConfig())
                    if (response.data.code === 0) {
                        store.commit('setSnackBar', {
                            message: i18n.t('changePhone.verify-sent-phone'),
                            color: 'success'
                        })
                        this.setStepNo(2)
                    } else {
                        if (response.data.message === '此手機號碼已註冊過、請直接登入') {
                            response.data.message = i18n.t('changePhone.mobile-registered')
                        }
                        this.showMsgDialog(response.data.message, i18n.t('changePhone.close'))
                    }
                } catch (error) {
                    this.showMsgDialog(i18n.t('changePhone.update-info-fail'), i18n.t('changePhone.close'))
                } finally {
                    this.closeDialog()
                }
            }
        }
    },
    activated() {
        store.commit('setTitle', this.titles[0])
        store.commit('setSideBtnType', SideBtnType.Back)
        this.init()
    }
}
</script>

<style scoped>
</style>
