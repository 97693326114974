<script>
import store from '@/store'

export const UserStatus = {
    PhoneVerified: 1 << 3,
    EmailVerified: 1 << 4
}

export default {
    methods: {
        checkUserStatus(status) {
            return store.getters.user.status && ((store.getters.user.status & status) === status)
        }
    }
}
</script>